import { Avatar, Button, Input } from "@mui/joy";
import { Link, useNavigate, useParams } from "react-router-dom"

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { CenteredLoader } from "../../Utils";
import { LocalSeeOutlined } from "@mui/icons-material";
import Pagination from "../../../api/Pagination";

export const Banlist = ({ user }: any) => {

    const [data, setData] = useState<any[]>([]);
    const [userData, setUserData] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, _setPage] = useState(1);
    const [totalItems, _setTotalItems] = useState(500);
    const [pageData, setPageData] = useState<any[]>([]);
    const navigate = useNavigate();

    const setPage = (page: number) => {
        _setPage(page);
        let start = totalItems * (page - 1);
        let end = start + totalItems;
        setPageData(data.slice(start, end));
    }

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            /*var ud = await getUserData();
            setUserData(ud);
            if (!isUserValid(ud)) {
                navigate('/login');
                return;
            }*/
            if (user.player_permlevel < 80) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/banlist?page=${page}&pageSize=${totalItems}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const usersData = await response.json();

            console.log(usersData);

            setData(usersData);
            setLoading(false);
        }

        load();
    }, [navigate]);

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
    };

    const filteredUsers = data.filter(user =>
        user.player_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const reloadUsers = async () => {
        setLoading(true);
        var userIdentityToken = localStorage.getItem("user");
        const response = await fetch(`${server}/account/users/get`, {
            method: 'GET',
            headers: {
                Authorization: userIdentityToken ? userIdentityToken : '',
            },
            mode: 'cors',
        })
            .finally(() => setLoading(false));
        const usersData = await response.json();

        setData(usersData);
    }


    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Spieler ({data.length})</h1>
                            <div className="flex items-center gap-4 flex-wrap">
                                <form className="flex-1 sm:flex-initial">
                                    <div className="relative">
                                        <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                        <Input
                                            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white rounded-lg shadow-md"
                                            id="search"
                                            placeholder="Spieler suchen..."
                                            type="search"
                                            startDecorator={< SearchIcon />}
                                            onChange={(event) => {
                                                setSearchTerm(event.target.value);
                                            }}
                                        />
                                    </div>
                                </form>
                                <Button className="shrink-0 rounded-lg shadow-md">
                                    <ArrowUpDownIcon className="w-4 h-4 mr-2" />
                                    Sort by
                                </Button>
                            </div>
                        </div>
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            {!isLoading ? filteredUsers.map((user) => (
                                <>
                                    <Link to={`/players/${user.player_name}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                                        <Avatar className="w-14 h-14 border">
                                            <img
                                                src={`https://crafatar.com/avatars/${user.uuid}`}
                                                alt={`Avatar of ${user.player_name}`}
                                            />
                                        </Avatar>
                                        <div className="grid gap-1">
                                            <h3 className="font-semibold dark:text-gray-200">{user.player_name}</h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">{user.reason}</p>
                                        </div>
                                    </Link>
                                </>
                            )) : (
                                <>
                                    <CenteredLoader />
                                </>
                            )}
                        </div>
                    </div>
                    <Pagination
                            current={page}
                            onPageClick={(page: number) => setPage(page)}
                        />
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}


function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}
