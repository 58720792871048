import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { formatNumber, formatTime, getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { Avatar, Button, Input } from "@mui/joy";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { CenteredLoader } from "../../Utils";
import { Switch, dialogActionsClasses } from "@mui/material";


export const RealestateView = ({ user }: any) => {
    const { number } = useParams();
    const navigate = useNavigate();
    const [userData, setUserData] = useState();
    const [realestate, setRealestate] = useState<any>(null);
    const [form, setForm] = useState({
        isActive: false,
        forumID: 0,
        forumID_Leader: 0,
        doGangwar: false,
        hasLaboratory: false,
        hasBlacklist: false,
        TeamSpeakID: 0,
        ChannelGroupID: 0,
        maxMember: 0
    });
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            if (user.player_permlevel < 70) {
                navigate('/');
                return;
            }

            try {
                const userIdentityToken = localStorage.getItem("user");
                const response = await fetch(`${server}/admin/economy/realestate/get/${number}`, {
                    method: 'GET',
                    headers: {
                        Authorization: userIdentityToken || '',
                    },
                    mode: 'cors',
                });

                const estateData = await response.json();
                if (!estateData) {
                    navigate('/economy');
                    return;
                }

                // Check if renter is a string and parse it
                if (typeof estateData.renter === 'string') {
                    try {
                        estateData.renter = JSON.parse(estateData.renter);
                    } catch (e) {
                        console.error("Failed to parse renter data", e);
                        estateData.renter = [];
                    }
                }

                // Ensure renter is an array
                if (!Array.isArray(estateData.renter)) {
                    estateData.renter = Object.entries(estateData.renter).map(([uuid, price]) => ({ uuid, price }));
                }

                setRealestate(estateData);
            } catch (error) {
                console.error("Failed to load real estate data", error);
            } finally {
                setLoading(false);
            }
        };

        load();
    }, [navigate, number, user.player_permlevel]);

    const saveChanges = async () => {
        try {
            const userIdentityToken = localStorage.getItem('user');
            await fetch(`${server}/admin/faction/${number}/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userIdentityToken || '',
                },
                mode: 'cors',
                body: JSON.stringify(form),
            });
        } catch (error) {
            console.error("Failed to save changes", error);
        }
    };

    const handleActiveChange = (event: any) => {
        setForm((prevForm) => ({ ...prevForm, isActive: event.target.checked }));
    };

    const handleSwitchChange = (event: any) => {
        const { name, checked } = event.target;
        setForm((prevForm) => ({ ...prevForm, [name]: checked }));
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                <CenteredLoader />
            </div>
        );
    }

    if (!user || !realestate) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                <CenteredLoader />
            </div>
        );
    }

    return (
        <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
            <div className="flex items-center gap-4">
                <Avatar className="h-24 w-24">
                    <BusinessCenterIcon />
                </Avatar>
                <div className="grid gap-1">
                    <h1 className="text-2xl font-bold dark:text-gray-200">Haus {realestate.number}</h1>
                    <p className="text-gray-500 dark:text-gray-400">
                        ID: {realestate.id} | Besitzer:{" "}
                        <Link to={`/players/${realestate.player_name}`}>{realestate.player_name}</Link>
                    </p>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-xl font-semibold dark:text-gray-200">Info</h2>
                <div className="grid gap-1">
                    <div className="flex items-center justify-between">
                        <p className="text-gray-500 dark:text-gray-400">Preis</p>
                        <p className="text-gray-500 dark:text-gray-400">{formatNumber(realestate.price)}</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-gray-500 dark:text-gray-400">Kasse</p>
                        <p className="text-gray-500 dark:text-gray-400">{formatNumber(realestate.money)}$</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-gray-500 dark:text-gray-400">Umsatz</p>
                        <p className="text-gray-500 dark:text-gray-400">{formatNumber(realestate.totalmoney)}$</p>
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-xl font-semibold dark:text-gray-200">Verwaltung</h2>
                <div className="grid gap-1">
                    <div className="flex items-center justify-between">
                        <p className="text-gray-500 dark:text-gray-400">Preis</p>
                        <Input
                            name="price"
                            value={realestate.price}
                            onChange={handleChange}
                            className="text-gray-500 dark:text-gray-400"
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button onClick={saveChanges}>Update</Button>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-xl font-semibold dark:text-gray-200">Mieter</h2>
                <div className="grid gap-2">
                    {Array.isArray(realestate.renter) && realestate.renter.length > 0 ? (
                        realestate.renter.map((player: any) => (
                            <Player key={player.uuid} uuid={player.uuid} price={player.price} />
                        ))
                    ) : (
                        <p className="text-gray-500 dark:text-gray-400">No renters available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

const Player = ({ uuid, price }: any) => {
    return (
        <div className="flex gap-4 items-center p-2 rounded-md bg-gray-100 dark:bg-gray-800 shadow-sm">
            <Avatar className="w-12 h-12">
                <BusinessCenterIcon />
            </Avatar>
            <div>
                <p className="font-medium dark:text-gray-200">{uuid}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Kosten: ${price}`}</p>
            </div>
        </div>
    );
};