import { useState } from 'react';
import '../../../index.css';
import { server } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { Forbidden } from './Forbidden';
import { getUserData } from '../../api/Utils';
import { CenteredLoader } from '../Utils';

let Login = () => {

    const [form, setForm] = useState({ user: '', password: '' });
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isForbidden, setForbidden] = useState(false);

    const navigate = useNavigate();

    let handleLogin = (e: any) => {
        e.preventDefault();
        setLoading(true);
        fetch(`${server}/account/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    throw new TypeError("Could not find user or Password is invalid.");
                }
            })
            .then((data) => {
                setForm({ user: '', password: '' });
                localStorage.setItem('user', JSON.stringify(data));
                const user: any = getUserData();
                if (user.player_permlevel < 60) {
                    localStorage.setItem('user', "");
                    setForbidden(true);
                    return;
                }
                navigate('/');
            })
            .catch((error) => {
                setErrorMessage(error.message);
                navigate('/login');
            })
            .finally(() => setLoading(false));
    };

    const handleChange = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    return (
        <>
        {isForbidden && (<Forbidden />)}
        <div className="bg-neutral-900 min-w-screen min-h-screen">
            <div className="relative py-1 sm:max-w-xl sm:mx-auto">
            {errorMessage && (
            <div
                    className={`relative px-4 py-10 bg-red-700/20 mx-8 md:mx-0 shadow rounded-3xl sm:p-8 mt-10`}
                >
                        <div className="text-gray-100">
                            {errorMessage}
                        </div>
                    
                </div>
            )}
            </div>
            <div className="justify-center items-center relative py-1 sm:max-w-xl sm:mx-auto">
                <div
                    className="relative px-4 py-10 bg-neutral-400/10 mx-8 md:mx-0 shadow rounded-3xl sm:p-10 mt-10"
                >
                    <div className="max-w-md mx-auto">
                        <form>
                            <div className="mt-5 grid sm:mx-10 gap-5">
                                <div>
                                    <label
                                        className="font-semibold text-sm text-gray-100 pb-1 block"
                                        htmlFor="username"
                                    >Ingame Name</label
                                    >
                                    <input
                                        required
                                        className="text-neutral-400 border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full bg-neutral-700/75 border-neutral-400"
                                        type="text"
                                        placeholder="Notch"
                                        id="username"
                                        name="user"
                                        onChange={handleChange}
                                        value={form.user}
                                    />
                                </div>
                                <div>
                                    <label
                                        className="font-semibold text-sm text-gray-100 pb-1 block"
                                        htmlFor="password"
                                    >Password</label
                                    >
                                    <input
                                        required
                                        className="text-neutral-400 border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full bg-neutral-700/75 border-neutral-400"
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        id="password"
                                        name="password"
                                        onChange={handleChange}
                                        value={form.password}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <button
                                    className="py-2 px-4 bg-orange-500 hover:bg-orange-500/90 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                    onClick={handleLogin}
                                >
                                    {isLoading ? (
                                        <CenteredLoader />
                                    ) : (
                                        <span>Login</span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
      </>
    );
    
    
};

export default Login;