import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { server } from "../../../../App";
import { Avatar, Button, Input } from "@mui/joy";
import { CenteredLoader } from "../../Utils";

import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

const AddItem = ({ onClose, onSave }: any) => {
    const [form, setForm] = useState({ material: '', name: '', weaponSound: '', type: '' });
    const [isLoading, setLoading] = useState(false);

    const save = () => {
        onSave(form);
        setForm({ material: '', name: '', weaponSound: '', type: '' });
    }

    const handleInputChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
                <div className="flex justify-end space-x-2">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 relative">

                        <button className="absolute right-3 top-3 text-gray-700 hover:text-gray-900 dark:text-white z-10" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        {isLoading && (
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-gray-200 opacity-5 absolute inset-0"></div>
                                <CenteredLoader />
                            </div>
                        )}

                        <div className={`p-6 space-y-4 md:space-y-6 sm:p-8 ${isLoading ? 'opacity-50' : ''}`}>
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Waffe hinzufügen
                            </h1>
                            <form className="space-y-4 md:space-y-6" action="#">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                    <input
                                        onChange={handleInputChange}
                                        type="text"
                                        value={form.name}
                                        name="name"
                                        id="name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Name"
                                        disabled={isLoading}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Material</label>
                                    <input
                                        onChange={handleInputChange}
                                        type="text"
                                        value={form.material}
                                        name="material"
                                        id="material"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Material"
                                        disabled={isLoading}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Primärfarbe</label>
                                    <input
                                        onChange={handleInputChange}
                                        type="text"
                                        value={form.weaponSound}
                                        name="weaponSound"
                                        id="weaponSound"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="WeaponSound"
                                        disabled={isLoading}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sekundärfarbe</label>
                                    <input
                                        onChange={handleInputChange}
                                        type="text"
                                        value={form.type}
                                        name="type"
                                        id="type"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Type"
                                        disabled={isLoading}
                                        required
                                    />
                                </div>
                                <button onClick={save} disabled={isLoading} type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Add</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const ShopInfoView = ({ user }: any) => {
    const { shop } = useParams();
    const navigate = useNavigate();
    const [shopData, setShopData] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalShown, setModalShown] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permission < 90) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/gameplay/shop/get/${shop}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const shopsData = await response.json();
            setShopData(shopsData);

            setLoading(false);
        }

        load();
    }, [navigate]);

    const filteredItems = shopData.items.filter((item: any) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openModal = () => {
        setModalShown(true);
    }

    const closeModal = () => {
        setModalShown(false);
    }

    const addItem = (form: any) => {
        setModalShown(false);
    }

    const Item = ({ id, icon, title }: any) => {
        return (
            <Link to={`${id}`} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                <Avatar className="w-14 h-14 border">
                    {icon}
                </Avatar>
                <div className="grid gap-1">
                    <h3 className="font-semibold dark:text-gray-200">{title}</h3>
                </div>
            </Link>
        )
    }

    return (
        <>
            {user && !isLoading ? (
                <>
                    {shopData && (
                        <>
                            {isModalShown && (
                                <AddItem
                                    onClose={closeModal}
                                    onSave={addItem}
                                />
                            )}
                            <div className="container mx-auto px-4 md:px-6 py-8">
                                <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                                    <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Items ({shopData.items.length})</h1>
                                    <div className="flex items-center gap-4 flex-wrap">
                                        <form className="flex-1 sm:flex-initial">
                                            <div className="relative">
                                                <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                                <Input
                                                    className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white rounded-lg shadow-md"
                                                    id="search"
                                                    placeholder="Waffe suchen..."
                                                    type="search"
                                                    startDecorator={< SearchIcon />}
                                                    onChange={(event) => {
                                                        setSearchTerm(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </form>
                                        <Button onClick={openModal} className="shrink-0 rounded-lg shadow-md">
                                            <AddIcon className="w-4 h-4 mr-2" />
                                            Erstellen
                                        </Button>
                                    </div>
                                </div>
                                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                                    {!isLoading && !isModalShown ? (
                                        <>
                                            {shopData.items && filteredItems.map((item: any) => (
                                                <Item
                                                    title={item.name.substring(2)}
                                                    id={item.id}
                                                    icon={< CreateIcon />}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {!isModalShown && (
                                                <CenteredLoader />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}


function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}


function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}