import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CenteredLoader } from "../../Utils";
import { Avatar, Input } from "@mui/joy";

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { server } from "../../../../App";

export const ShopView = ({ user }: any) => {
    const navigate = useNavigate();
    const [shops, setShops] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permission < 90) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/gameplay/shop/get`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const shopsData = await response.json();
            setShops(shopsData);

            setLoading(false);
        }

        load();
    }, [navigate]);

    const filteredShops = shops.filter(shop =>
        shop.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Shops ({shops.length})</h1>
                            <div className="flex items-center gap-4 flex-wrap">
                                <form className="flex-1 sm:flex-initial">
                                    <div className="relative">
                                        <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                        <Input
                                            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white rounded-lg shadow-md"
                                            id="search"
                                            placeholder="Shop suchen..."
                                            type="search"
                                            startDecorator={< SearchIcon />}
                                            onChange={(event) => {
                                                setSearchTerm(event.target.value);
                                            }}
                                        />
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            {!isLoading ? filteredShops.map((shop) => (
                                <Shop
                                    title={shop.name}
                                    link={shop.name.toLowerCase().replace(" ", "-")}
                                    icon={< ShoppingBasketIcon />
                                    }
                                />
                            )) : (
                                <>
                                    <CenteredLoader />
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Shop = ({ link, icon, title }: any) => {
    return (
        <Link to={`${link}`} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{title}</h3>
            </div>
        </Link>
    )
}


function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}


function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}