import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { server } from "../../../../App";
import { Avatar } from "@mui/joy";

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { formatNumber, formatTime } from "../../../api/Utils";
import { CenteredLoader } from "../../Utils";

export const Vehicle = ({user}: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [vehicle, setVehicle] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/economy/vehicle/get/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const vehicleData = await response.json();

            if (vehicleData === null) {
                navigate('/economy');
            }

            console.log(vehicleData);

            setVehicle(vehicleData);
            setLoading(false);
        }

        load();
    }, [navigate]);
    return(
        <>
        
            {user && !isLoading ? (
                <>
                    {vehicle ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-24 w-24">
                                        <DirectionsCarIcon />
                                    </Avatar>
                                    <div className="grid gap-1">
                                        <h1 className="text-2xl font-bold dark:text-gray-200">{`Fahrzeug #${vehicle.id}`}</h1>
                                        <p className="text-gray-500 dark:text-gray-400">{vehicle.type}</p>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Info</h2>
                                    <div className="grid gap-1">
                                    <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Besitzer</p>
                                            <a className="text-gray-500 dark:text-gray-400"><Link to={`/players/${vehicle.player_name}`}>{vehicle.player_name}</Link></a>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Garage</p>
                                            <p className="text-gray-500 dark:text-gray-400">{vehicle.garage_name}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Kilometer</p>
                                            <a className="text-gray-500 dark:text-gray-400">{`${formatNumber(vehicle.km)}`}</a>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Tank</p>
                                            <a className="text-gray-500 dark:text-gray-400">{vehicle.fuel} Liter</a>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Gekauft</p>
                                            <a className="text-gray-500 dark:text-gray-400">{formatTime(vehicle.boughtAt)}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
        
    )
}