import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { getUserData, isUserValid } from "../../api/Utils";
import { useEffect, useState } from "react";
import { Alert, Avatar, Typography } from "@mui/joy";

import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import ApiIcon from '@mui/icons-material/Api';
import QuizIcon from '@mui/icons-material/Quiz';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import BookIcon from '@mui/icons-material/Book';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import BlockIcon from '@mui/icons-material/Block';
import { CenteredLoader } from "../Utils";
import { Users } from "./Users/Users";
import { User } from "./Users/User";
import { Settings } from "./Settings/Settings";
import { SettingAccountView } from "./Settings/Account";
import { Analytics } from "./Analytics";
import { CompaniesView } from "./Factions/Factions";
import { FactionView } from "./Factions/Faction";
import { useMediaQuery } from "@mui/material";
import { Economy } from "./Economy/Economy";
import { Transaction } from "./Economy/Transaction";
import { Vehicle } from "./Economy/Vehicle";
import { Gameplay } from "./Gameplay/Gameplay";
import { WeaponView } from "./Gameplay/Weapon";
import { WeaponInfo } from "./Gameplay/WeaponInfo";
import { ShopView } from "./Gameplay/Shop";
import { ShopInfoView } from "./Gameplay/ShopInfo";
import { ShopItem } from "./Gameplay/ShopItem";
import { RealEstatesView } from "./Economy/Realestates";
import { RealestateView } from "./Economy/Realestate";
import { Rules } from "./Rules/Rules";
import { Rule } from "./Rules/Rule";
import { Logs } from "./Logs/Logs";
import { ChatLog } from "./Logs/ChatLog";
import { CommandLog } from "./Logs/CommandLogs";
import { BankLogs } from "./Logs/BankLogs";
import { FactionBankLogs } from "./Logs/FactionBankLogs";
import { MoneyLogs } from "./Logs/MoneyLogs";
import { Banlist } from "./Users/Banlist";
import { Contracts } from "./Gameplay/Contracts";

export const DashboardView = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    useEffect(() => {
        const load = async () => {
            var ud = await getUserData();
            setUserData(ud);
            if (!isUserValid(ud)) {
                navigate('/login');
                return;
            }
        }

        load();
    }, [navigate]);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const isDesktopOrLaptop = useMediaQuery(
        '(min-device-width: 768px)'
    )

    return (
        <>
            {userData ? (
                <div className="flex flex-col h-screen bg-gray-100 dark:bg-gray-900">
                    <header
                        className={`px-6 py-2 bg-white dark:bg-gray-800 shadow ${isSidebarOpen && !isDesktopOrLaptop && "hidden"}`}
                    >
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <WavingHandIcon className="w-8 h-8 text-gray-800 dark:text-gray-200" />
                                <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                                    Hallo, {userData.player_name}
                                </h1>
                            </div>

                            {!isDesktopOrLaptop && (
                                <button
                                    onClick={() => setIsSidebarOpen(true)}
                                    className={`text-gray-500 focus:outline-none focus:text-gray-600 ${isDesktopOrLaptop && "hidden"}`}
                                >
                                    <svg
                                        className="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                                    </svg>
                                </button>
                            )}
                        </div>
                    </header>
                    <div className="flex flex-1 overflow-hidden">
                        <aside className={`transform top-0 left-0 w-64 bg-white dark:bg-gray-800 p-4 overflow-y-auto fixed h-full z-30 transition-transform duration-200 ease-in-out ${!isSidebarOpen && "-translate-x-full"} md:translate-x-0 md:static`}>
                            <nav className="space-y-1">
                                <button onClick={() => setIsSidebarOpen(false)} className="md:hidden"><ArrowBackIosIcon color="info" /></button>

                                <Link
                                    className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                    to="/"
                                >
                                    <DashboardIcon className="w-5 h-5 mr-2" />
                                    Dashboard
                                </Link>
                                {userData.player_permlevel >= 70 && (
                                    <>
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/players"
                                        >
                                            <GroupIcon className="w-5 h-5 mr-2" />
                                            Spieler
                                        </Link>
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/banlist"
                                        >
                                            <BlockIcon className="w-5 h-5 mr-2" />
                                            Banliste
                                        </Link>
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/factions"
                                        >
                                            <BusinessIcon className="w-5 h-5 mr-2" />
                                            Fraktionen
                                        </Link>
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/logs"
                                        >
                                            <AnnouncementIcon className="w-5 h-5 mr-2" />
                                            Logs
                                        </Link>
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/economy"
                                        >
                                            <AttachMoneyIcon className="w-5 h-5 mr-2" />
                                            Wirtschaft
                                        </Link>
                                        {userData.player_permlevel >= 90 && (
                                            <><Link
                                                className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                                to="/gameplay"
                                            >
                                                <VideogameAssetIcon className="w-5 h-5 mr-2" />
                                                Spielgeschehen
                                            </Link>
                                                <Link
                                                    className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                                    to="/rules"
                                                >
                                                    <BookIcon className="w-5 h-5 mr-2" />
                                                    Regeln
                                                </Link>
                                            </>
                                        )}
                                        <Link
                                            className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                            to="/analytics"
                                        >
                                            <BarChartIcon className="w-5 h-5 mr-2" />
                                            Analyse
                                        </Link>
                                    </>
                                )}
                                <Link
                                    className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                                    to="/settings"
                                >
                                    <SettingsIcon className="w-5 h-5 mr-2" />
                                    Einstellungen
                                </Link>
                            </nav>
                        </aside>
                        <main className="flex-1 p-4 overflow-y-auto">
                            <Routes>
                                <Route path="/" element={<>
                                    <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Dashboard</h2>
                                    <Alert
                                        variant="soft"
                                        startDecorator={<WarningIcon />}
                                        color="warning"
                                        key={"Booking-Info"}
                                    >
                                        <div>
                                            <div>Booking-Info</div>
                                            <Typography level="body-sm" color="warning">
                                                The booking IDs are not service provider specific.
                                            </Typography>
                                        </div>
                                    </Alert>
                                    <br />
                                    <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Help & FAQ</h2>
                                    <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-4">
                                        <ActionButton
                                            title={"FAQ"}
                                            link={"/"}
                                            icon={< QuizIcon />}
                                        />
                                        <ActionButton
                                            title={"Support"}
                                            link={"/"}
                                            icon={< SupportAgentIcon />}
                                        />
                                        <ActionButton
                                            title={"Configure API keys"}
                                            link={"/"}
                                            description={"Explains how to get the API keys for the booking system"}
                                            icon={< ApiIcon />}
                                        />
                                    </div>
                                    <br />
                                    <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Security & Safety</h2>
                                    <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-4">
                                        <ActionButton
                                            title={"Change Password"}
                                            link={"/settings/account"}
                                            description={"We recommend changing the password regularly"}
                                            icon={< SecurityIcon />}
                                        />
                                    </div>
                                </>
                                } />
                                <Route path="players" element={< Users user={userData} />} />
                                <Route path="players/:id" element={< User user={userData} />} />
                                <Route path="banlist" element={< Banlist user={userData} />} />
                                <Route path="analytics" element={< Analytics user={userData} />} />
                                <Route path="factions" element={< CompaniesView user={userData} />} />
                                <Route path="factions/:faction" element={< FactionView user={userData} />} />
                                <Route path="settings" element={< Settings user={userData} />} />
                                <Route path="settings/account" element={< SettingAccountView user={userData} />} />
                                <Route path="economy" element={< Economy user={userData} />} />
                                <Route path="economy/realestates" element={< RealEstatesView user={userData} />} />
                                <Route path="economy/realestates/:number" element={< RealestateView user={userData} />} />
                                <Route path="economy/transaction/:id" element={< Transaction user={userData} />} />
                                <Route path="economy/vehicle/:id" element={< Vehicle user={userData} />} />
                                <Route path="gameplay" element={<Gameplay user={userData} />} />
                                <Route path="gameplay/weapon" element={<WeaponView user={userData} />} />
                                <Route path="gameplay/weapon/:name" element={<WeaponInfo user={userData} />} />
                                <Route path="gameplay/contracts" element={<Contracts user={userData} />} />
                                <Route path="gameplay/shop" element={<ShopView user={userData} />} />
                                <Route path="gameplay/shop/:shop" element={<ShopInfoView user={userData} />} />
                                <Route path="gameplay/shop/:shop/:id" element={<ShopItem user={userData} />} />
                                <Route path="rules" element={<Rules user={userData} />} />
                                <Route path="rules/:id" element={<Rule user={userData} />} />
                                <Route path="logs" element={<Logs user={userData} />} />
                                <Route path="logs/chat" element={<ChatLog user={userData} />} />
                                <Route path="logs/commands" element={<CommandLog user={userData} />} />
                                <Route path="logs/bank" element={<BankLogs user={userData} />} />
                                <Route path="logs/factionbank" element={<FactionBankLogs user={userData} />} />
                                <Route path="logs/money" element={<MoneyLogs user={userData} />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    );
}

const ActionButton = ({ link, icon, title, description }: any) => {
    return (
        <>
            {description !== undefined ? (
                <Link to={link} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                    <Avatar className="w-14 h-14 border">
                        {icon}
                    </Avatar>
                    <div className="grid gap-1">
                        <h3 className="font-semibold dark:text-gray-200">{title}</h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
                    </div>
                </Link>
            ) : (
                <Link to={link} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                    <Avatar className="w-14 h-14 border">
                        {icon}
                    </Avatar>
                    <div className="grid gap-1">
                        <h3 className="font-semibold dark:text-gray-200">{title}</h3>
                    </div>
                </Link>
            )}
        </>
    )
}