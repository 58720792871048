import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Login from './components/views/Login/Login';
import { DashboardView } from './components/views/Dashboard/Index';

export const server = "https://api.voidroleplay.de"

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/login" element={< Login />} />
        <Route path='/*' element={< DashboardView />} />
      </Routes>
    </div>
  );
}

export default App;