import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Analytics = ({user}: any) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    useEffect(() => {
        const load = async () => {
            /*var ud = await getUserData();
            setUserData(ud);
            if (!isUserValid(ud)) {
                navigate('/login');
                return;
            }*/
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
        }

        load();
    }, [navigate]);
    return (
        <>
            <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Analytics</h2>
            {user ? (
                <></>
            ) : (
                <></>
            )}
        </>
    );
}