import { Avatar, Button, Input } from "@mui/joy"
import { Link, useNavigate } from "react-router-dom"
import { formatNumber, formatTime } from "../../../api/Utils"
import { useEffect, useState } from "react"
import { server } from "../../../../App"
import { CenteredLoader } from "../../Utils"

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Pagination from "../../../api/Pagination"

export const BankLogs = ({ user }: any) => {

    const [data, setData] = useState<any[]>([]);
    const [page, _setPage] = useState(1);
    const [totalItems, _setTotalItems] = useState(500);
    const [pageData, setPageData] = useState<any[]>([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const setPage = (page: number) => {
        _setPage(page);
        let start = totalItems * (page - 1);
        let end = start + totalItems;
        setPageData(data.slice(start, end));
    }

    useEffect(() => {
        const load = async () => {
            if (user.player_permlevel < 60) {
                return navigate('/');;
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/log/bank?page=${page}&pageSize=${totalItems}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const data = await response.json();
            console.log(data);
            setData(data);

            let start = totalItems * (page - 1);
            let end = start + totalItems;
            setPageData(data.slice(start, end));
        }

        load();
    }, [navigate, page, totalItems]);

    const filteredMessages = data.filter(message =>
        message.amount.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <>
            {user ? (
                <>
                    {data ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                                    <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Transaktionen ({data.length})</h1>
                                    <div className="flex items-center gap-4 flex-wrap">
                                        <form className="flex-1 sm:flex-initial">
                                            <div className="relative">
                                                <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                                <Input
                                                    className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white rounded-lg shadow-md"
                                                    id="search"
                                                    placeholder="Suche Transaktion ($)..."
                                                    type="search"
                                                    startDecorator={< SearchIcon />}
                                                    onChange={(event: any) => {
                                                        setSearchTerm(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </form>
                                        <Button className="shrink-0 rounded-lg shadow-md">
                                            <ArrowUpDownIcon className="w-4 h-4 mr-2" />
                                            Sort by
                                        </Button>

                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Banklogs</h2>
                                    <div className="grid gap-1">
                                        {filteredMessages.map((log: any) => (
                                            <>
                                                <BankLog
                                                    isPlus={log.isPlus}
                                                    id={log.id}
                                                    amount={log.amount}
                                                    reason={log.reason.replace("-", " ")}
                                                    date={log.datum}
                                                />
                                            </>
                                        )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Pagination
                            current={page}
                            onPageClick={(page: number) => setPage(page)}
                        />
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const BankLog = ({ id, isPlus, amount, reason, date }: any) => {
    return (
        <Link to={`/economy/transaction/${id}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {!isPlus ? (
                    <RemoveIcon />
                ) : (
                    <AddIcon />
                )}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">
                    {isPlus ? 'Eingang' : 'Ausgang'} - {formatNumber(amount)}$
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{reason + " - " + formatTime(date)}</p>
            </div>
        </Link>
    )
}

function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}

function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}