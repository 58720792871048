import { Avatar } from "@mui/joy";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CenteredLoader } from "../../Utils";

import NoteIcon from '@mui/icons-material/Note';

export const Logs = ({user}: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        const load = async () => {
            if (user.player_permission < 90) {
                return navigate('/');
            }
        }

        load();
    }, [navigate]);
    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            <Setting
                                title="Bank"
                                link="bank"
                                icon={< NoteIcon />}
                            />
                            <Setting
                                title="Bargeld"
                                link="money"
                                icon={< NoteIcon />}
                            />
                            <Setting
                                title="Fraktionsbank"
                                link="factionbank"
                                icon={< NoteIcon />}
                            />
                            <Setting
                                title="Befehle"
                                link="commands"
                                icon={< NoteIcon />} 
                            />
                            <Setting
                                title="Chat"
                                link="chat"
                                icon={< NoteIcon />} 
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Setting = ({ link, icon, title }: any) => {
    return (
        <Link to={link} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{title}</h3>
            </div>
        </Link>
    )
}