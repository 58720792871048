import { Avatar, Button, Input } from "@mui/joy";
import { Link, useNavigate, useParams } from "react-router-dom"

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState } from "react";
import { formatNumber, formatTime, getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { CenteredLoader } from "../../Utils";

import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import GavelIcon from '@mui/icons-material/Gavel';
import { Switch } from "@mui/material";

export const User = ({ user }: any) => {
    const { id } = useParams();

    const [targetUser, setTargetUser] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const navigate = useNavigate();
    const [form, setForm] = useState<any>({ permission: 0, bookingAccount: false });
    const [bankLogLimit, setBankLogLimit] = useState(100); // Initial limit for bank logs
    const [moneyLogLimit, setMoneyLogLimit] = useState(100); // Initial limit for cash logs

    const loadMoreBankLogs = () => {
        setBankLogLimit(prevLimit => prevLimit + 100); // Increase the limit by 5 or any preferred number
    };

    const loadMoreMoneyLogs = () => {
        setMoneyLogLimit(prevLimit => prevLimit + 100);
    };


    useEffect(() => {
        const load = async () => {
            /*var ud = await getUserData();
            setUserData(ud);
            if (!isUserValid(ud)) {
                navigate('/login');
                return;
            }*/
            if (user.player_permlevel < 80) {
                return navigate('/');;
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/player/get/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const usersData = await response.json();

            usersData.banking = usersData.banking.sort((a: { datum: string }, b: { datum: string }) => new Date(b.datum).getTime() - new Date(a.datum).getTime());

            setTargetUser(usersData);

            setForm({ permission: usersData.player_permlevel });
        }

        load();
    }, [navigate]);

    const handleUpdate = () => {
        var user = localStorage.getItem('user');
        fetch(`${server}/admin/profile/${targetUser.username}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
    };

    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleBookingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, bookingAccount: event.target.checked });
    };

    return (
        <>
            {user ? (
                <>
                    {targetUser ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-24 w-24">
                                        <img
                                            src={`https://crafatar.com/avatars/${targetUser.uuid}`}
                                            alt={`Avatar of ${targetUser.player_name}`}
                                        />
                                    </Avatar>
                                    <div className="grid gap-1">
                                        <h1 className="text-2xl font-bold dark:text-gray-200">{targetUser.player_name} {targetUser.player_permlevel >= 60 && (< HdrAutoIcon color="error" />)}</h1>
                                        <p className="text-gray-500 dark:text-gray-400">{`ID: ${targetUser.id} | UUID: ${targetUser.uuid}`}</p>
                                    </div>
                                </div>
                                {targetUser.ban && (
                                    <>
                                        <div className="grid gap-1 bg-red-500/70 rounded-lg pr-4 pl-r p-2">
                                            <h1 className="text-2xl font-bold text-white">{`Spieler ist gebannt ${targetUser.ban.isPermanent === 1 && ('(Permanent)')}`}</h1>
                                            <p className="text-gray-200">{`Admin: ${targetUser.ban.punisher} | Uhrzeit: ${formatTime(targetUser.ban.punishDate)}`}</p>
                                            <p className="text-gray-200">{`Grund: ${targetUser.ban.reason} ${targetUser.ban.isPermanent === 0 ? (`| Läuft ab: ${formatTime(targetUser.ban.date)}`) : ''}`}</p>
                                        </div>
                                    </>
                                )}
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Info</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Account erstellt</p>
                                            <p className="text-gray-500 dark:text-gray-400">{formatTime(targetUser.firstjoin)}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Last Login</p>
                                            <p className="text-gray-500 dark:text-gray-400">{formatTime(targetUser.lastLogin)}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Fraktion</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.faction ? (targetUser.faction + ` (Rang ${targetUser.faction_grade})`) : ('Zivilist')}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Business</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.business ? (targetUser.business + ` (Rang ${targetUser.business_grade})`) : ('Nicht vorhanden')}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Bargeld</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${formatNumber(targetUser.bargeld)}$`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Coins</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${formatNumber(targetUser.coins)}`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Bank</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${formatNumber(targetUser.bank)}$`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Visum</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${targetUser.visum} (${targetUser.current_hours}/${targetUser.needed_hours})`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Spielzeit</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${targetUser.playtime_hours} Stunden & ${targetUser.playtime_minutes} Minuten`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Level</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${targetUser.level} (${targetUser.exp}/${targetUser.needed_exp})`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Charakter</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.firstname ? (`${targetUser.firstname} ${targetUser.lastname}`) : ('Kein Character ersetllt')}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Todesstatus</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.isDead ? `${targetUser.deathTime} Sekunden${targetUser.isStabilized && (' - Stabilisiert')}${targetUser.isHitmanDead && (' - Hitman')}` : 'Am Leben'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Verknüpfungen</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Forum-ID</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.forumID ? `${targetUser.forumID}` : 'Nicht verbunden'}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">TeamSpeak-UID</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.teamSpeakUID ? `${targetUser.teamSpeakUID}` : 'Nicht verbunden'}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Discord</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.discordId ? `${targetUser.discordId}` : 'Nicht verbunden'}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">UCP</p>
                                            <p className="text-gray-500 dark:text-gray-400">{targetUser.ucp ? `Verbunden` : 'Nicht verbunden'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Administrative</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Permission</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="permission"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.permission}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <Button onClick={handleUpdate}>Update</Button>
                                </div>
                                {targetUser.vehicles && targetUser.vehicles.length >= 1 && (
                                    <div className="space-y-2">
                                        <h2 className="text-xl font-semibold dark:text-gray-200">Fahrzeuge</h2>
                                        <div className="grid gap-1">
                                            {targetUser.vehicles.map((vehicle: any) => (
                                                <>
                                                    <Vehicle
                                                        type={vehicle.type}
                                                        id={vehicle.id}
                                                    />
                                                </>
                                            )
                                            )}
                                        </div>
                                    </div>
                                )};

                                {targetUser.notes && targetUser.notes.length >= 1 && (
                                    <div className="space-y-2">
                                        <h2 className="text-xl font-semibold dark:text-gray-200">Notes</h2>
                                        <div className="grid gap-1">
                                            {targetUser.notes.map((note: any) => (
                                                <>
                                                    <Note
                                                        executor={note.executor}
                                                        note={note.note}
                                                        date={note.entryAdded}
                                                    />
                                                </>
                                            )
                                            )}
                                        </div>
                                    </div>
                                )};

                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Zahlungsverlauf</h2>
                                    <h2 className="text-l  dark:text-gray-200">Letzte 7 Tage</h2>
                                    <div className="grid gap-1">
                                        {targetUser.banking.slice(0, bankLogLimit).map((log: any) => (
                                            <BankLog
                                                key={log.id} // Add a key for React list rendering
                                                isPlus={log.isPlus}
                                                id={log.id}
                                                amount={log.amount}
                                                reason={log.reason.replace("-", " ")}
                                                date={log.datum}
                                            />
                                        ))}
                                        {targetUser.banking.length > bankLogLimit && (
                                            <Button onClick={loadMoreBankLogs} variant="solid">
                                                Mehr anzeigen
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Zahlungsverlauf (Bargeld)</h2>
                                    <h2 className="text-l  dark:text-gray-200">Letzte 7 Tage</h2>
                                    <div className="grid gap-1">
                                        {targetUser.moneylog.slice(0, moneyLogLimit).map((log: any) => (
                                            <BankLog
                                                key={log.id} // Add a key for React list rendering
                                                isPlus={log.isPlus}
                                                id={log.id}
                                                amount={log.amount}
                                                reason={log.reason.replace("-", " ")}
                                                date={log.datum}
                                            />
                                        ))}
                                        {targetUser.moneylog.length > moneyLogLimit && (
                                            <Button onClick={loadMoreMoneyLogs} variant={'solid'}>
                                                Mehr anzeigen
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Vehicle = ({ type, id }: any) => {
    return (
        <Link to={`/economy/vehicle/${id}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                <DirectionsCarIcon />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{type}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Klicke für mehr Informationen`}</p>
            </div>
        </Link>
    )
}

const Note = ({ executor, note, date }: any) => {
    return (
        <Link to={""} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                <GavelIcon />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">
                    {note}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{executor ? executor : 'System'} - {formatTime(date)}</p>
            </div>
        </Link>
    )
}

const BankLog = ({ id, isPlus, amount, reason, date }: any) => {
    return (
        <Link to={`/economy/transaction/${id}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {!isPlus ? (
                    <RemoveIcon />
                ) : (
                    <AddIcon />
                )}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">
                    {isPlus ? 'Eingang' : 'Ausgang'} - {formatNumber(amount)}$
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{reason + " - " + formatTime(date)}</p>
            </div>
        </Link>
    )
}