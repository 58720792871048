import { useEffect, useState } from "react";

interface IPagination {
    onPageClick: any;
    current: number;
}

const Pagination = ({ onPageClick, current }: IPagination) => {
    const [currentPage, setCurrentPage] = useState(current);

    const setPage = (page: number) => {
        if (page < 1) return;
        setCurrentPage(page);
        onPageClick(page);
    }

    const [currentPages, setCurrentPages] = useState<number[]>([1]);

    useEffect(() => {
        var start = currentPage - 2;
        if (start < 1) start = 1;
        const pages = Array.from({ length: 5 }, (_, i) => start + i);
        setCurrentPages(pages.filter(page => page > 0));
    }, [currentPage]);

    return (
        <>
            <button onClick={() => setPage(currentPage - 1)} className="bg-transparent m-1 p-0.5 pr-2 pl-2 text-xl"><i className="fa-solid fa-chevron-left">BEFORE</i></button>
            {currentPages.map((page: number, index: number) => (
                <button key={index} className={`${currentPage === page ? 'bg-orange-500 text-white' : 'bg-transparent text-black'} text-xl m-1 p-0.5 pr-2 pl-2`} onClick={() => setPage(page)}>{page}</button>
            ))}
            <button onClick={() => setPage(currentPage + 1)} className="bg-transparent m-1 p-0.5 pr-2 pl-2 text-xl"><i className="fa-solid fa-chevron-right">NEXT</i></button>
        </>
    )
}

export default Pagination;
