import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserData, isUserValid } from "../../../api/Utils";
import { CenteredLoader } from "../../Utils";
import { Avatar } from "@mui/joy";

import SettingsIcon from '@mui/icons-material/Settings';

export const Settings = ({user}: any) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    useEffect(() => {
        const load = async () => {
            if (user.permission < 300) {
                navigate('/login');
                localStorage.setItem('user', "");
                return;
            }
        }

        load();
    }, [navigate]);
    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            <Setting
                                title="Booking Settings"
                                link="booking"
                                icon={< SettingsIcon />}
                            />
                            <Setting
                                title="Account Settings"
                                link="account"
                                icon={< SettingsIcon />}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Setting = ({ link, icon, title }: any) => {
    return (
        <Link to={link} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{title}</h3>
            </div>
        </Link>
    )
}