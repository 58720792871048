import { Autocomplete, Avatar, Button, Card, CardContent, Input, Typography } from "@mui/joy"
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { server } from "../../../../App";
import { CenteredLoader } from "../../Utils";

import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';

export const Economy = ({ user }: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [statistics, setsTatistics] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/economy/statistics/get`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const statisticData = await response.json();

            if (statisticData === null) {
                navigate('/');
            }

            setsTatistics(statisticData);
            setLoading(false);
        }

        load();
    }, [navigate]);
    return (
        <>

            {user && !isLoading ? (
                <>
                    {statistics ? (
                        <>

                            <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-4">
                                <ActionButton
                                    title={"Immobilien"}
                                    link={"realestates"}
                                    icon={< HomeIcon />}
                                />
                                <ActionButton
                                    title={"Firmen"}
                                    link={"companies"}
                                    icon={< StoreIcon />}
                                />
                            </div>

                            <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-4">
                                <Card>
                                    <CardContent>
                                        <Typography level="title-md">Waffen</Typography>
                                        <form className="flex flex-col gap-4">
                                            {statistics.weapons.length} in umlauf
                                        </form>
                                    </CardContent>
                                </Card>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>

    )
}

const ActionButton = ({ link, icon, title, description }: any) => {
    return (
        <>
            {description !== undefined ? (
                <Link to={link} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                    <Avatar className="w-14 h-14 border">
                        {icon}
                    </Avatar>
                    <div className="grid gap-1">
                        <h3 className="font-semibold dark:text-gray-200">{title}</h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
                    </div>
                </Link>
            ) : (
                <Link to={link} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                    <Avatar className="w-14 h-14 border">
                        {icon}
                    </Avatar>
                    <div className="grid gap-1">
                        <h3 className="font-semibold dark:text-gray-200">{title}</h3>
                    </div>
                </Link>
            )}
        </>
    )
}