import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { formatNumber, formatTime, getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { Avatar, Button, Input } from "@mui/joy";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { CenteredLoader } from "../../Utils";
import { Switch, dialogActionsClasses } from "@mui/material";


export const Rule = ({ user }: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [rule, setRule] = useState<any>();
    const [form, setForm] = useState<any>({ isArea: false, area: "", isType: false, type: "", rule: "" });
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/rules/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const ruleData = await response.json();

            console.log(ruleData);

            if (ruleData === null) {
                navigate('/rules');
            }

            setForm({ ...form, isArea: ruleData.isArea, area: ruleData.area, isType: ruleData.isType, type: ruleData.type, rule: ruleData.rule });

            setRule(ruleData);
            setLoading(false);
        }

        load();
    }, [navigate]);

    const saveChanges = () => {
        var user = localStorage.getItem('user');
        fetch(`${server}/admin/rules/${id}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
    }

    const deleteRule = () => {
        var user = localStorage.getItem('user');
        fetch(`${server}/admin/rules/${id}/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
    }

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, isActive: event.target.checked });
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
    };

    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    return (
        <>
            {user && !isLoading ? (
                <>
                    {rule ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Verwaltung</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Area</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.isArea}
                                                    onChange={handleSwitchChange}
                                                    name="isArea"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Type</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.isType}
                                                    onChange={handleSwitchChange}
                                                    name="isType"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Area</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="area"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.area}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Type</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="type"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.type}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Rule</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="rule"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.rule}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <Button onClick={deleteRule} color="danger">Delete</Button>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button onClick={saveChanges}>Update</Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Player = ({ username, icon, uuid, rank }: any) => {
    return (
        <Link to={`/players/${username}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{username}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Rang ${rank}`}</p>
            </div>
        </Link>
    )
}

const GangwarZone = ({ name, lastAttack }: any) => {
    return (
        <Link to={`/gangwar/${name}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                <ApartmentIcon />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{name}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Letzter Angriff am ${formatTime(lastAttack)}`}</p>
            </div>
        </Link>
    )
}