import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { formatNumber, formatTime, getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { Avatar, Button, Input } from "@mui/joy";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { CenteredLoader } from "../../Utils";
import { Switch, dialogActionsClasses } from "@mui/material";


export const FactionView = ({ user }: any) => {
    const { faction } = useParams();
    const navigate = useNavigate();
    const [userData, setUserData] = useState();
    const [factionData, setFaction] = useState<any>();
    const [form, setForm] = useState<any>({ isActive: false, forumID: 0, forumID_Leader: 0, doGangwar: false, hasLaboratory: false, hasBlacklist: false, TeamSpeakID: 0, ChannelGroupID: 0, maxMember: 0 });
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/faction/get/${faction}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const companyData = await response.json();

            console.log(companyData);

            if (companyData === null) {
                navigate('/factions');
            }

            const sortedMembers = [...companyData.member].sort((a, b) => b.faction_grade - a.faction_grade);
            companyData.member = sortedMembers;

            setForm({ ...form, isActive: companyData.isActive, forumID: companyData.forumID, forumID_Leader: companyData.forumID_Leader, doGangwar: companyData.doGangwar, hasLaboratory: companyData.hasLaboratory, hasBlacklist: companyData.hasBlacklist, TeamSpeakID: companyData.TeamSpeakID, ChannelGroupID: companyData.ChannelGroupID, maxMember: companyData.maxMember });

            setFaction(companyData);
            setLoading(false);
        }

        load();
    }, [navigate]);

    const saveChanges = () => {
        var user = localStorage.getItem('user');
        fetch(`${server}/admin/faction/${faction}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
    }

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, isActive: event.target.checked });
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
    };

    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    return (
        <>
            {user && !isLoading ? (
                <>
                    {factionData ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-24 w-24">
                                        <BusinessCenterIcon />
                                    </Avatar>
                                    <div className="grid gap-1">
                                        <h1 className="text-2xl font-bold dark:text-gray-200">{factionData.fullname}</h1>
                                        <p className="text-gray-500 dark:text-gray-400">{`ID: ${factionData.id} | Kürzel: ${factionData.name}`}</p>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Info</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Mitglieder</p>
                                            <p className="text-gray-500 dark:text-gray-400">{`${factionData.memberCount}/${factionData.maxMember}`}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Bank</p>
                                            <a className="text-gray-500 dark:text-gray-400">{`${formatNumber(factionData.bank)}$`}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Verwaltung</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Aktiv</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.isActive}
                                                    onChange={handleActiveChange}
                                                    name="lopusAccount"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Gangwar</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.doGangwar}
                                                    onChange={handleSwitchChange}
                                                    name="doGangwar"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Labor</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.hasLaboratory}
                                                    onChange={handleSwitchChange}
                                                    name="hasLaboratory"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Blacklist</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.hasBlacklist}
                                                    onChange={handleSwitchChange}
                                                    name="hasBlacklist"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Maximale Mitglieder</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="maxMember"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.maxMember}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">ForumID</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="forumID"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.forumID}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">ForumID (Leader)</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="forumID_Leader"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.forumID_Leader}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">TeamSpeakID</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="TeamSpeakID"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.TeamSpeakID}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">ChannelGroupID</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="ChannelGroupID"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.ChannelGroupID}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button onClick={saveChanges}>Update</Button>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Mitglieder</h2>
                                    <div className="grid gap-1">
                                        {factionData.member &&
                                            factionData.member.map((player: any) => (
                                                <>
                                                    <Player
                                                        username={player.player_name}
                                                        rank={player.faction_grade}
                                                        uuid={player.uuid}
                                                    />
                                                </>
                                            )
                                            )}
                                    </div>
                                </div>
                                {factionData.gangwarZones && factionData.gangwarZones.length >= 1 && (
                                    <div className="space-y-2">
                                        <h2 className="text-xl font-semibold dark:text-gray-200">Gangwar-Gebiete</h2>
                                        <div className="grid gap-1">
                                            {factionData.gangwarZones.map((zone: any) => (
                                                <>
                                                    <GangwarZone
                                                        name={zone.zone}
                                                        lastAttack={zone.lastAttack}
                                                    />
                                                </>
                                            )
                                            )}
                                        </div>
                                    </div>
                                )};
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Player = ({ username, icon, uuid, rank }: any) => {
    return (
        <Link to={`/players/${username}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{username}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Rang ${rank}`}</p>
            </div>
        </Link>
    )
}

const GangwarZone = ({ name, lastAttack }: any) => {
    return (
        <Link to={`/gangwar/${name}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                <ApartmentIcon />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{name}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`Letzter Angriff am ${formatTime(lastAttack)}`}</p>
            </div>
        </Link>
    )
}