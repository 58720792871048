import { Avatar, Button, Input } from "@mui/joy";
import { Link, useNavigate, useParams } from "react-router-dom"

import AddIcon from '@mui/icons-material/Add';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useEffect, useState } from "react";
import { getUserData, isUserValid } from "../../../api/Utils";
import { server } from "../../../../App";
import { CenteredLoader } from "../../Utils";

export const RealEstatesView = ({ user }: any) => {

    const [realestates, setFactions] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                navigate('/')
                return;
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/economy/realestate/get`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const companiesData = await response.json();

            setFactions(companiesData);
            setLoading(false);
        }

        load();
    }, [navigate]);

    const filteredRealEstates = realestates.filter(realestate =>
        realestate.number.toString().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="flex items-center justify-between mb-6 sm:flex-row flex-col">
                            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Immobilien ({realestates.length})</h1>
                            <div className="flex items-center gap-4 flex-wrap">
                                <form className="flex-1 sm:flex-initial">
                                    <div className="relative">
                                        <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                        <Input
                                            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white rounded-lg shadow-md"
                                            id="search"
                                            placeholder="Immobilie suchen..."
                                            type="search"
                                            startDecorator={< SearchIcon />}
                                            onChange={(event) => {
                                                setSearchTerm(event.target.value);
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            {!isLoading && !isAddUserModalOpen ? filteredRealEstates.map((estate) => (
                                <>
                                    <Link to={`${estate.number}`} className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
                                        <Avatar className="w-14 h-14 border">
                                            <BusinessCenterIcon />
                                        </Avatar>
                                        <div className="grid gap-1">
                                            <h3 className="font-semibold dark:text-gray-200">Haus {estate.number}</h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">{estate.owner ? (estate.player_name) : ('Frei')}</p>
                                        </div>
                                    </Link>
                                </>
                            )) : (
                                <>
                                    {!isAddUserModalOpen && (
                                        <CenteredLoader />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

function ArrowUpDownIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
        </svg>
    )
}


function SearchIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}
