import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CenteredLoader } from "../../Utils";
import { Avatar } from "@mui/joy";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PolicyIcon from '@mui/icons-material/Policy';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NavigationIcon from '@mui/icons-material/Navigation';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export const Gameplay = ({user}: any) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    const [weapons, setWeapons] = useState<any[]>([]);
    useEffect(() => {
        const load = async () => {
            if (user.player_permission < 90) {
                return navigate('/');
            }
        }

        load();
    }, [navigate]);
    return (
        <>
            {user ? (
                <>
                    <div className="container mx-auto px-4 md:px-6 py-8">
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                            <Setting
                                title="Waffen"
                                link="weapon"
                                icon={< PolicyIcon />}
                            />
                            <Setting
                                title="Shops"
                                link="shop"
                                icon={< ShoppingCartIcon />}
                            />
                            <Setting
                                title="Fahrzeuge"
                                link="vehicle"
                                icon={< DirectionsCarIcon />} 
                            />
                            <Setting
                                title="Navigation"
                                link="navigation"
                                icon={< NavigationIcon />} 
                            />
                            <Setting
                                title="Kopfgelder"
                                link="contracts"
                                icon={< MyLocationIcon />} 
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Setting = ({ link, icon, title }: any) => {
    return (
        <Link to={link} className="flex gap-4 items-center rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                {icon}
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{title}</h3>
            </div>
        </Link>
    )
}