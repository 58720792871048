import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server } from "../../../../App";

export const ShopItem = ({user}: any) => {
    const { shop, id } = useParams();
    const navigate = useNavigate();
    const [itemData, setItemData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalShown, setModalShown] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permission < 90) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/gameplay/item/get/${shop}/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const shopsData = await response.json();
            setItemData(shopsData);
            console.log(shopsData);

            setLoading(false);
        }

        load();
    }, [navigate]);
    return (
        <>
        </>
    )
}