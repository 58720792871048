import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { server } from "../../../../App";
import { Avatar } from "@mui/joy";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { formatNumber, formatTime } from "../../../api/Utils";
import { CenteredLoader } from "../../Utils";

export const Transaction = ({user}: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [transaction, setTransaction] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permlevel < 70) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/economy/transaction/get/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const transacionData = await response.json();

            console.log(transacionData);

            if (transacionData === null) {
                navigate('/economy');
            }

            setTransaction(transacionData);
            setLoading(false);
        }

        load();
    }, [navigate]);
    return(
        <>
        
            {user && !isLoading ? (
                <>
                    {transaction ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-24 w-24">
                                        <AccountBalanceIcon />
                                    </Avatar>
                                    <div className="grid gap-1">
                                        <h1 className="text-2xl font-bold dark:text-gray-200">{`Transaktion #${transaction.id}`}</h1>
                                        <p className="text-gray-500 dark:text-gray-400">{formatTime(transaction.datum)}</p>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Info</h2>
                                    <div className="grid gap-1">
                                    <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Durchgeführt von</p>
                                            <a className="text-gray-500 dark:text-gray-400"><Link to={`/players/${transaction.player_name}`}>{transaction.player_name}</Link></a>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Typ</p>
                                            <p className="text-gray-500 dark:text-gray-400">{transaction.isPlus ? ('Einzahlung') : ('Auszahlung')}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Betrag</p>
                                            <a className="text-gray-500 dark:text-gray-400">{`${formatNumber(transaction.amount)}$`}</a>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Grund</p>
                                            <a className="text-gray-500 dark:text-gray-400">{transaction.reason}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
        
    )
}