import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getUserData, isUserValid } from "../../../api/Utils";
import { CenteredLoader } from "../../Utils";
import { Button, Card, CardContent, Input, Typography } from "@mui/joy";
import { server } from "../../../../App";

export const SettingAccountView = ({user}: any) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    const [form, setForm] = useState({email: '', password: ''});
    useEffect(() => {
        const load = async () => {
            /*var ud = await getUserData();
            setUserData(ud);
            if (!isUserValid(ud)) {
                navigate('/login');
                return;
            }*/
        }

        load();
    }, [navigate]);

    const handleEmailUpdate = () => {
        console.log(form);
        var user = localStorage.getItem('user');
        fetch(`${server}/account/updatemail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    throw new TypeError("Could not find user or Password is invalid.");
                }
            })
            .then((data) => {
                setForm({ email: '', password: '' });
            })
            .catch((error) => {
            })
            .finally();
    }

    const handlePasswordUpdate = () => {
        console.log(form);
        var user = localStorage.getItem('user');
        fetch(`${server}/account/updatepassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    throw new TypeError("Could not find user or Password is invalid.");
                }
            })
            .then((data) => {
                setForm({ email: '', password: '' });
            })
            .catch((error) => {
            })
            .finally();
    }
    
    const handleChange = (e: any) => {
        setForm({... form, [e.target.name]: e.target.value});
    }

    return (
        <>
            {user ? (
                <>
                    <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-4">
                        <Card>
                            <CardContent>
                                <Typography level="title-md">Email</Typography>
                                <form className="flex flex-col gap-4">
                                    <Input onChange={handleChange} value={form.email} name="email" placeholder="New Email" />
                                    <Button onClick={handleEmailUpdate}>Update</Button>
                                </form>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography level="title-md">Password</Typography>
                                <form className="flex flex-col gap-4">
                                    <Input onChange={handleChange} value={form.password} name="password" placeholder="New Password" />
                                    <Button onClick={handlePasswordUpdate}>Update</Button>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}