import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CenteredLoader } from "../../Utils";
import { Avatar, Button, Input } from "@mui/joy";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PolicyIcon from '@mui/icons-material/Policy';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NavigationIcon from '@mui/icons-material/Navigation';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { server } from "../../../../App";
import { Switch } from "@mui/material";

export const WeaponInfo = ({user}: any) => {
    const navigate = useNavigate();
    const {name} = useParams();
    const [userData, setUserData] = useState<any>();
    const [weapon, setWeapon] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [form, setForm] = useState({id: 0, material: '', name: '', weaponSound: '', soundPitch: 0.0, type: '', maxAmmo: 0, reloadDuration: 0.0, damage: 0.0, velocity: 0.0, shootDuration: 0.0, isMeele: false, knockback: 0});
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (user.player_permission < 90) {
                return navigate('/');
            }
            var userIdentityToken = localStorage.getItem("user");
            const response = await fetch(`${server}/admin/gameplay/weapons/get/${name}`, {
                method: 'GET',
                headers: {
                    Authorization: userIdentityToken ? userIdentityToken : '',
                },
                mode: 'cors',
            });
            const weaponsData = await response.json();
            setWeapon(weaponsData);
            console.log(weaponsData);

            setForm({...form, id: weaponsData.id, material: weaponsData.material, name: weaponsData.name, weaponSound: weaponsData.weaponSound, soundPitch: weaponsData.soundPitch, type: weaponsData.type, maxAmmo: weaponsData.maxAmmo, reloadDuration: weaponsData.reloadDuration, damage: weaponsData.damage, velocity: weaponsData.velocity, shootDuration: weaponsData.shootDuration, isMeele: weaponsData.isMeele, knockback: weaponsData.knockback});

            setLoading(false);
        }

        load();
    }, [navigate]);

    const saveChanges = () => {
        var user = localStorage.getItem('user');
        fetch(`${server}/admin/gameplay/weapon/${name}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: user ? user : '',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        })
    }

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
    };

    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }
    return (
        <>
            {user && !isLoading ? (
                <>
                    {weapon ? (
                        <>
                            <div className="w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-24 w-24">
                                        <PolicyIcon />
                                    </Avatar>
                                    <div className="grid gap-1">
                                        <h1 className="text-2xl font-bold dark:text-gray-200">{weapon.name.substring(2)}</h1>
                                        <p className="text-gray-500 dark:text-gray-400">{`ID: ${weapon.id} | Kürzel: ${weapon.type}`}</p>
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    <h2 className="text-xl font-semibold dark:text-gray-200">Anpassen</h2>
                                    <div className="grid gap-1">
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Nahkampf</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Switch
                                                    checked={form.isMeele}
                                                    onChange={handleSwitchChange}
                                                    name="lopusAccount"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Material</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="material"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.material}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Name</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.name}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Sound</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="weaponSound"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.weaponSound}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Sound-Pitch</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="soundPitch"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.soundPitch}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Typ</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="type"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={form.type}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Max.-Schuss</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="maxAmmo"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.maxAmmo}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Reload Zeit</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="reloadDuration"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.reloadDuration}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Schaden</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="damage"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.damage}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Velocity</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="velocity"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.velocity}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Abklingzeit (Shoot-Duration)</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="shootDuration"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.shootDuration}
                                                />
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-gray-500 dark:text-gray-400">Rückstoß</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                                <Input
                                                    name="knockback"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={form.knockback}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button onClick={saveChanges}>Update</Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}
